var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.errorMessage ? _c('AlertArea', {
    attrs: {
      "type": _setup.alertType,
      "no-margin": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        var _setup$crmSyncObject, _setup$crmSyncObject2, _setup$crmSyncObject3, _setup$crmSyncObject4, _vm$$datetime;
        return [_vm._v("\n    " + _vm._s(_setup.errorMessage) + "\n    "), (_setup$crmSyncObject = _setup.crmSyncObject) !== null && _setup$crmSyncObject !== void 0 && _setup$crmSyncObject.crm ? _c('div', {
          staticClass: "d-flex align-center"
        }, [(_setup$crmSyncObject2 = _setup.crmSyncObject) !== null && _setup$crmSyncObject2 !== void 0 && _setup$crmSyncObject2.get_crm_display ? _c('LinkText', {
          staticClass: "text-body-2",
          attrs: {
            "text": (_setup$crmSyncObject3 = _setup.crmSyncObject) === null || _setup$crmSyncObject3 === void 0 ? void 0 : _setup$crmSyncObject3.get_crm_display,
            "to": (_setup$crmSyncObject4 = _setup.crmSyncObject) === null || _setup$crmSyncObject4 === void 0 ? void 0 : _setup$crmSyncObject4.crm_url
          }
        }) : _vm._e(), _vm._v(" "), _c('span', {
          staticClass: "text-caption secondary-text--text ml-2"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('lastSyncAt', [(_vm$$datetime = _vm.$datetime(_setup.crmSyncObject.last_synced_at)) !== null && _vm$$datetime !== void 0 ? _vm$$datetime : _setup.$t('notSync')])) + "\n      ")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 97005063)
  }, [_vm._v(" "), _setup.syncStatus === 'not_found' ? _c('button', {
    staticClass: "tw-btn tw-btn-text-primary tw-shrink-0",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_setup.$t('action.doUnsync'))
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:unsync');
      }
    }
  }) : _setup.syncStatus === 'unsynchronized' ? _c('button', {
    staticClass: "tw-btn tw-btn-text-primary tw-shrink-0",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_setup.$t('action.doSync'))
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:sync');
      }
    }
  }) : _setup.isSettingErrorStatus && _setup.crmName !== 'CRM' ? _c('nuxt-link', {
    staticClass: "tw-btn tw-btn-text-primary tw-shrink-0",
    attrs: {
      "to": "/settings/integration/".concat(_setup.crmName.toLowerCase())
    },
    domProps: {
      "textContent": _vm._s(_setup.$t('action.doCheckSetting'))
    }
  }) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };