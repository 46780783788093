import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('VerticalTable', {
    attrs: {
      "headers": _setup.headers,
      "item": _setup.account,
      "threshold": 420
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn() {
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "before": _setup.account.name,
            "after": _setup.crmAccount.name
          }
        })];
      },
      proxy: true
    }, {
      key: "item.site_url",
      fn: function fn() {
        return [_c(_setup.ConfirmMigrationRow, {
          attrs: {
            "before": _setup.account.site_url,
            "after": _setup.crmAccount.site_url
          }
        })];
      },
      proxy: true
    }, {
      key: "item.address",
      fn: function fn() {
        return [_c(_setup.ConfirmMigrationRow, {
          scopedSlots: _vm._u([_setup.account.shipping_addresses.length ? {
            key: "before",
            fn: function fn() {
              return _vm._l(_setup.account.shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-2': index > 0
                  },
                  attrs: {
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              });
            },
            proxy: true
          } : null, _setup.account.shipping_addresses.length + _setup.crmAccount.shipping_addresses.length ? {
            key: "after",
            fn: function fn() {
              return [_vm._l(_setup.account.shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-2': index > 0
                  },
                  attrs: {
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              }), _vm._v(" "), _vm._l(_setup.crmAccount.shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: _setup.account.shipping_addresses.length + index,
                  class: {
                    'mt-2': _setup.account.shipping_addresses.length + index > 0
                  },
                  attrs: {
                    "text-class": "font-weight-black",
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              })];
            },
            proxy: true
          } : null], null, true)
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };