import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": "CRM情報の編集（デバッグ）",
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.$t('action.save')
      },
      "valid": _setup.isValid,
      "loading": _setup.loading,
      "before-close": _setup.beforeClose,
      "persistent": ""
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(VCardText, {
    staticClass: "py-2"
  }, [_c('div', {
    staticClass: "tw-flex tw-flex-row tw-gap-4 tw-mt-4 tw-mb-8 tw-text-on-surface"
  }, [_c('TwRadio', {
    attrs: {
      "current": _setup.method,
      "text": "プロパティ変更",
      "value": "props",
      "name": "test"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.method = $event;
      }
    }
  }), _vm._v(" "), _c('TwRadio', {
    attrs: {
      "current": _setup.method,
      "text": "ステータス変更",
      "value": "status",
      "name": "test"
    },
    on: {
      "update:current": function updateCurrent($event) {
        _setup.method = $event;
      }
    }
  })], 1), _vm._v(" "), _c(VRow, {
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "label": "crm",
      "disabled": _setup.method !== 'props'
    },
    model: {
      value: _setup.crm,
      callback: function callback($$v) {
        _setup.crm = $$v;
      },
      expression: "crm"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "label": "crm_id",
      "disabled": _setup.method !== 'props'
    },
    model: {
      value: _setup.crmId,
      callback: function callback($$v) {
        _setup.crmId = $$v;
      },
      expression: "crmId"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('Input', {
    attrs: {
      "label": "crm_url",
      "disabled": _setup.method !== 'props'
    },
    model: {
      value: _setup.crmUrl,
      callback: function callback($$v) {
        _setup.crmUrl = $$v;
      },
      expression: "crmUrl"
    }
  })], 1), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('Input', {
    attrs: {
      "label": "sync_status",
      "type": "select",
      "clearable": false,
      "items": ['no_sync', 'active', 'inactive', 'api_error', 'crm_error', 'mapping_error', 'not_found', 'unsynchronized'],
      "disabled": _setup.method !== 'status'
    },
    model: {
      value: _setup.syncStatus,
      callback: function callback($$v) {
        _setup.syncStatus = $$v;
      },
      expression: "syncStatus"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };