/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./EditCrmDialog.vue?vue&type=template&id=6a52c7b9"
import script from "./EditCrmDialog.vue?vue&type=script&lang=ts&setup=true"
export * from "./EditCrmDialog.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TwRadio: require('/code/src/components/input/TwRadio.vue').default,Input: require('/code/src/components/input/Input.vue').default,SimpleDialog: require('/code/src/components/dialog/SimpleDialog.vue').default})
