import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm$$datetime;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "d-flex align-center mb-2"
  }, [_c('h6', [_vm._v(_vm._s(_setup.$t('crmSync')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.$config.public.debug && _setup.crmSyncObject ? [_c('button', {
    staticClass: "tw-btn tw-btn-text-secondary !tw-p-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.editCrm
    }
  }, [_vm._v("\n        " + _vm._s('変更(デバッグ)') + "\n      ")])] : _vm._e(), _vm._v(" "), _setup.crm ? _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
    attrs: {
      "type": "button",
      "disabled": !_setup.crmSyncObject
    },
    domProps: {
      "textContent": _vm._s(_setup.$t('action.unsync'))
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:unsync');
      }
    }
  }) : _vm._e(), _vm._v(" "), _setup.syncStatus === 'unsynchronized' ? _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-fill-primary ml-2",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_setup.$t('action.startSync'))
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:sync');
      }
    }
  }) : _vm._e()], 2), _vm._v(" "), _setup.crm ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_setup.crmDisplay ? _c('LinkText', {
    staticClass: "text-body-2",
    attrs: {
      "text": _setup.crmDisplay,
      "to": _setup.crmUrl
    }
  }) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "text-caption secondary-text--text ml-2"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('lastSyncAt', [(_vm$$datetime = _vm.$datetime(_setup.lastSyncedAt)) !== null && _vm$$datetime !== void 0 ? _vm$$datetime : _setup.$t('notSync')])) + "\n    ")])], 1) : _c('div', {
    staticClass: "text-body-2 disabled-text--text"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('notSync')) + "\n  ")]), _vm._v(" "), _setup.syncStatus === 'unsynchronized' ? _c('div', {
    staticClass: "mt-2"
  }, [_c(VIcon, {
    attrs: {
      "small": "",
      "color": "secondary-text"
    }
  }, [_vm._v("mdi-information-outline")]), _vm._v(" "), _c('span', {
    staticClass: "text-caption secondary-text--text"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('warning.notYetSynchronized', [_setup.crmName])) + "\n    ")])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };