import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm$account;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('accountDetail')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.$config.public.debug ? _c('button', {
    staticClass: "tw-btn tw-btn-text-secondary !tw-p-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.deleteAccount
    }
  }, [_vm._v("\n      " + _vm._s('削除(デバッグ)') + "\n    ")]) : _vm._e(), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.add = true;
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.addContact')) + "\n    ")])], 1), _vm._v(" "), ((_vm$account = _vm.account) === null || _vm$account === void 0 ? void 0 : _vm$account.sync_status) !== 'unsynchronized' ? _c('CrmObjectSyncAlert', {
    staticClass: "mt-4",
    attrs: {
      "crm-sync-object": _vm.account
    },
    on: {
      "click:sync": _vm.syncToCrm,
      "click:unsync": _vm.unsyncFromCrm
    }
  }) : _vm._e(), _vm._v(" "), _c('SplitView', {
    ref: "splitView",
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.mdAndDown
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        var _vm$account2, _vm$account3, _vm$account3$shipping;
        return [_c('ContentArea', [_c('div', {
          staticClass: "d-flex mb-4 align-center"
        }, [_c('h6', [_vm._v(_vm._s(_vm.$t('accountInfo')))]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
          attrs: {
            "type": "button",
            "disabled": _vm.loading.initial
          },
          domProps: {
            "textContent": _vm._s(_vm.$t('action.edit'))
          },
          on: {
            "click": function click($event) {
              _vm.dialog.edit = true;
            }
          }
        })], 1), _vm._v(" "), _c('VerticalTable', {
          attrs: {
            "headers": [{
              text: _vm.$t('accountName'),
              value: 'name'
            }, {
              text: _vm.$t('webSite'),
              value: 'site_url'
            }, {
              text: _vm.$t('address'),
              value: 'address'
            }],
            "item": _vm.account,
            "dummy": _vm.loading.initial
          },
          scopedSlots: _vm._u([(_vm$account2 = _vm.account) !== null && _vm$account2 !== void 0 && _vm$account2.site_url ? {
            key: "item.site_url",
            fn: function fn(item) {
              return [_c('LinkText', {
                attrs: {
                  "text": item.site_url,
                  "to": item.site_url
                }
              })];
            }
          } : null, (_vm$account3 = _vm.account) !== null && _vm$account3 !== void 0 && (_vm$account3$shipping = _vm$account3.shipping_addresses) !== null && _vm$account3$shipping !== void 0 && _vm$account3$shipping.length ? {
            key: "item.address",
            fn: function fn(_ref) {
              var shipping_addresses = _ref.shipping_addresses;
              return _vm._l(shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-2': index > 0
                  },
                  attrs: {
                    "detail": "",
                    "rows": "",
                    "address": address
                  }
                });
              });
            }
          } : null], null, true)
        }), _vm._v(" "), _c('CrmObjectSyncInfoSection', {
          staticClass: "mt-6",
          attrs: {
            "crm-sync-object": _vm.account
          },
          on: {
            "update:crmSyncObject": function updateCrmSyncObject($event) {
              _vm.account = $event;
            },
            "update:crm-sync-object": function updateCrmSyncObject($event) {
              _vm.account = $event;
            },
            "click:sync": _vm.syncToCrm,
            "click:unsync": _vm.unsyncFromCrm
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "second",
      fn: function fn() {
        return [_c('ContentArea', [_c('h6', {
          staticClass: "mb-6"
        }, [_vm._v(_vm._s(_vm.$t('contactList')))]), _vm._v(" "), _c('CustomizableHeaderTable', {
          attrs: {
            "sort-desc": ['created_at'],
            "headers": [{
              text: '氏名',
              value: 'name',
              getter: _vm.$fullName,
              hideable: false
            }, {
              text: '敬称',
              value: 'title',
              empty: _vm.$t('notSet')
            }, {
              text: '部署',
              value: 'department',
              empty: _vm.$t('notSet')
            }, {
              text: '役職',
              value: 'job_title',
              empty: _vm.$t('notSet')
            }, {
              text: 'メールアドレス',
              value: 'email',
              empty: _vm.$t('notSet')
            }, {
              text: '電話番号',
              value: 'tel',
              empty: _vm.$t('notSet')
            }, {
              text: 'CRM',
              value: 'crm',
              empty: _vm.$t('notLink'),
              ellipsis: false
            }, {
              text: '住所',
              value: 'shipping_addresses',
              ellipsis: false,
              empty: _vm.$t('notReg')
            }, {
              text: '送付数',
              value: 'order_count',
              align: 'center',
              transform: function transform(val) {
                return _vm.$t('case', [val.toLocaleString()]);
              }
            }, {
              text: 'キャンペーン数',
              value: 'campaign_count',
              align: 'center',
              transform: function transform(val) {
                return _vm.$t('case', [val.toLocaleString()]);
              }
            }, {
              text: '最終送付日',
              value: 'last_sent',
              transform: _vm.$date,
              empty: _vm.$t('none')
            }, {
              text: '送付可否',
              value: 'delivery_block',
              align: 'center'
            }, {
              text: '所有者(担当者)',
              value: 'owner.name'
            }, {
              text: 'コンタクト作成日',
              value: 'created_at',
              transform: _vm.$date
            }, {
              text: 'ノート',
              value: 'note',
              align: 'center'
            }, {
              text: 'タグ',
              value: 'tags',
              empty: _vm.$t('notSet')
            }, {
              text: '削除',
              value: 'remove_action',
              align: 'center',
              hideable: false
            }],
            "default-headers": ['氏名', '部署', '役職', '送付数', 'キャンペーン数', '最終送付日', '送付可否', '所有者(担当者)', 'コンタクト作成日', 'ノート', '削除'],
            "items": _vm.contacts,
            "item-key": "id",
            "loading": _vm.loading.account,
            "options": _vm.tableOptions,
            "server-items-length": _vm.totalItems,
            "disable-sort": true
          },
          on: {
            "update:options": function updateOptions($event) {
              _vm.tableOptions = $event;
            },
            "click:row": function clickRow(item, _, event) {
              return _vm.onClickItem(item, event);
            },
            "auxclick:row": function auxclickRow(event, row) {
              return _vm.onClickItem(row.item, event);
            }
          },
          scopedSlots: _vm._u([{
            key: "item.shipping_addresses",
            fn: function fn(_ref2) {
              var _item$shipping_addres;
              var item = _ref2.item;
              return [(_item$shipping_addres = item.shipping_addresses) !== null && _item$shipping_addres !== void 0 && _item$shipping_addres.length ? _c('FloatingTooltip', {
                attrs: {
                  "top": "",
                  "hoverable": "",
                  "max-width": "400px",
                  "open-delay": "500"
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref3) {
                    var _ref4, _item$shipping_addres2, _item$shipping_addres3;
                    var on = _ref3.on,
                      attrs = _ref3.attrs;
                    return [_c('div', _vm._g(_vm._b({
                      staticClass: "d-flex"
                    }, 'div', attrs, false), on), [_c('AddressLabel', {
                      staticStyle: {
                        "pointer-events": "none"
                      },
                      attrs: {
                        "small": "",
                        "detail": "",
                        "rows": "",
                        "max-width": "200",
                        "alert": false,
                        "address": (_ref4 = (_item$shipping_addres2 = item.shipping_addresses.find(function (it) {
                          var _item$primary_shippin;
                          return it.id === ((_item$primary_shippin = item.primary_shipping_address) === null || _item$primary_shippin === void 0 ? void 0 : _item$primary_shippin.id);
                        })) !== null && _item$shipping_addres2 !== void 0 ? _item$shipping_addres2 : item.shipping_addresses[0]) !== null && _ref4 !== void 0 ? _ref4 : {}
                      },
                      scopedSlots: _vm._u([((_item$shipping_addres3 = item.shipping_addresses) === null || _item$shipping_addres3 === void 0 ? void 0 : _item$shipping_addres3.length) > 1 ? {
                        key: "label.prepend",
                        fn: function fn() {
                          return [_c('span', {
                            staticClass: "ml-1 text-body-2 secondary-text--text"
                          }, [_vm._v("\n                        " + _vm._s('+' + _vm.$t('case', [item.shipping_addresses.length - 1])) + "\n                      ")])];
                        },
                        proxy: true
                      } : null], null, true)
                    })], 1)];
                  }
                }], null, true)
              }, [_vm._v(" "), _vm._l(item.shipping_addresses, function (address, index) {
                return _c('AddressLabel', {
                  key: index,
                  class: {
                    'mt-1': index > 0
                  },
                  attrs: {
                    "detail": "",
                    "rows": "",
                    "small": "",
                    "address": address,
                    "color": "white"
                  }
                });
              })], 2) : _vm._e()];
            }
          }, {
            key: "item.crm",
            fn: function fn(_ref5) {
              var _vm$$datetime;
              var item = _ref5.item;
              return [item !== null && item !== void 0 && item.crm_id ? [item !== null && item !== void 0 && item.get_crm_display ? _c('LinkText', {
                attrs: {
                  "text": item === null || item === void 0 ? void 0 : item.get_crm_display,
                  "to": item === null || item === void 0 ? void 0 : item.crm_url
                }
              }) : _vm._e(), _vm._v(" "), _c('div', {
                staticClass: "text-caption secondary-text--text"
              }, [_vm._v("\n                " + _vm._s(_vm.$t('lastSyncAt', [(_vm$$datetime = _vm.$datetime(item.last_synced_at)) !== null && _vm$$datetime !== void 0 ? _vm$$datetime : _vm.$t('notSync')])) + "\n              ")])] : _vm._e()];
            }
          }, {
            key: "item.delivery_block",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("\n              " + _vm._s(item.delivery_block ? 'mdi-close' : 'mdi-circle-outline') + "\n            ")])];
            }
          }, {
            key: "item.note",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [item !== null && item !== void 0 && item.description ? _c(VMenu, {
                attrs: {
                  "open-on-hover": "",
                  "offset-y": "",
                  "close-on-content-click": false
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref8) {
                    var on = _ref8.on,
                      attrs = _ref8.attrs;
                    return [_c(VIcon, _vm._g(_vm._b({
                      attrs: {
                        "small": ""
                      }
                    }, 'v-icon', attrs, false), on), [_vm._v("\n                  mdi-note-outline\n                ")])];
                  }
                }], null, true)
              }, [_vm._v(" "), _c(VCard, [_c(VCardText, [_c('pre', {
                domProps: {
                  "textContent": _vm._s(item === null || item === void 0 ? void 0 : item.description)
                }
              })])], 1)], 1) : _vm._e()];
            }
          }, {
            key: "item.tags",
            fn: function fn(_ref9) {
              var item = _ref9.item;
              return _vm._l(item.tags, function (tag) {
                return _c(VChip, {
                  key: tag,
                  staticClass: "tw-mr-2",
                  attrs: {
                    "outlined": "",
                    "small": ""
                  }
                }, [_c('span', {
                  directives: [{
                    name: "ellipsis",
                    rawName: "v-ellipsis"
                  }],
                  staticClass: "tw-max-w-[160px]"
                }, [_vm._v("\n                " + _vm._s(tag) + "\n              ")])]);
              });
            }
          }, {
            key: "item.remove_action",
            fn: function fn(_ref10) {
              var item = _ref10.item;
              return [(_vm.isEditDisabled = !!item.crm_id && item.sync_status !== 'unsynchronized') ? void 0 : _vm._e(), _vm._v(" "), _c('FloatingTooltip', {
                attrs: {
                  "top": "",
                  "max-width": "300px",
                  "disabled": !_vm.isEditDisabled
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref11) {
                    var on = _ref11.on,
                      attrs = _ref11.attrs;
                    return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('TextButton', {
                      attrs: {
                        "loading": _vm.loading.unlink,
                        "color": "error",
                        "disabled": _vm.isEditDisabled,
                        "value": _vm.$t('action.delete')
                      },
                      on: {
                        "click": function click($event) {
                          $event.stopPropagation();
                          return _vm.unlinkContact(item);
                        }
                      }
                    })], 1)];
                  }
                }], null, true)
              }, [_vm._v("\n              " + _vm._s(_vm.$t('warning.disabledUnlinkContactFromAccounts')) + "\n            ")])];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  }), _vm._v(" "), _vm.account ? [_c('EditAccountDialog', {
    attrs: {
      "show": _vm.dialog.edit
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "edit", $event);
      }
    },
    model: {
      value: _vm.account,
      callback: function callback($$v) {
        _vm.account = $$v;
      },
      expression: "account"
    }
  }), _vm._v(" "), _c('EditContactDialog', {
    attrs: {
      "show": _vm.dialog.add,
      "link-target-account": _vm.account
    },
    on: {
      "update:show": function updateShow($event) {
        return _vm.$set(_vm.dialog, "add", $event);
      },
      "change": function change($event) {
        // Note: 返却値のコンタクト情報に配送数などが含まれないので取得しなおす
        // contacts.unshift($event)
        _vm.tableOptions.page = 1;
        _vm.fetchAccount();
      },
      "create": function create($event) {
        // Note: 返却値のコンタクト情報に配送数などが含まれないので取得しなおす
        // contacts.unshift($event)
        _vm.tableOptions.page = 1;
        _vm.fetchAccount();
      }
    }
  }), _vm._v(" "), _c('ConfirmAccountMigrationDialog', {
    ref: "confirmDialog",
    on: {
      "click:positive": _vm.sync
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };