/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=2e923c35"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,CrmObjectSyncAlert: require('/code/src/components/widgets/CrmObjectSyncAlert.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,VerticalTable: require('/code/src/components/table/VerticalTable.vue').default,CrmObjectSyncInfoSection: require('/code/src/components/widgets/CrmObjectSyncInfoSection.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default,SplitView: require('/code/src/components/SplitView.vue').default})
