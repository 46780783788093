var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup._account && _setup._crmAccount ? _c('CustomDialog', {
    attrs: {
      "title": _setup.$t('confirmation'),
      "negative": _setup.$t('action.cancel'),
      "positive": _setup.$t('action.doSync'),
      "max-width": "800px"
    },
    on: {
      "click:positive": function clickPositive($event) {
        _setup.emits('click:positive');
        _setup._show = false;
      }
    },
    model: {
      value: _setup._show,
      callback: function callback($$v) {
        _setup._show = $$v;
      },
      expression: "_show"
    }
  }, [_c('span', {
    staticClass: "text-body-1"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('confirm.syncCrm', [_setup.crmName, _setup.crmTarget, _setup.$t('account')])) + "\n  ")]), _vm._v(" "), _setup._account.crm_url ? _c('div', {
    staticClass: "d-flex justify-center my-2"
  }, [_c('TextButton', {
    attrs: {
      "value": _setup.$t('checkSyncCrmObjectDetail', [_setup.crmName, _setup.crmTarget]),
      "href": _setup._account.crm_url,
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c(_setup.AccountDiffView, {
    staticClass: "mt-4",
    attrs: {
      "account": _setup._account,
      "crm-account": _setup._crmAccount
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };